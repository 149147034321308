@use "sass:map";

@import "custom-bs-import";

// Layout & components
// Only import what we need:
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
// @import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

:root {
    --doc-ptsize-max: 16pt;
}

.overscroll-none {
    overscroll-behavior: none;
}

.no-scrollbar {
    scrollbar-width: none;
}

.sidebar-toggle[aria-expanded="true"] .sidebar-shown {
    display: none;
}

.sidebar-toggle[aria-expanded="false"] .sidebar-collapsed {
    display: none;
}

.sidebar-toolbar {
    z-index: 1;
}

// Toggle classes for dark/light modes
[data-bs-theme="dark"] {
    .d-dm-none {
        display: none;
    }

    .d-lm-none {
        display: initial;
    }
}

[data-bs-theme="light"] {
    .d-dm-none {
        display: initial;
    }

    .d-lm-none {
        display: none;
    }
}

// Show theme toggler checkbox
.dropdown-menu .active .bi {
    display: block !important;
}

@media screen {
    @include media-breakpoint-down(md) {
        body {
            padding-top: 60px;
        }

        html {
            scroll-padding-top: 60px;
        }
    }

    @include media-breakpoint-down(sm) {
        body {
            padding-top: 70px;
        }

        html {
            scroll-padding-top: 70px;
        }
    }
}

.rfcmarkup,
.rfchtml {
    font-family: var(--bs-font-monospace);

    caption {
        padding: 0;
        color: var(--bs-body-color);
    }

    code {
        font-size: 1em;
        color: inherit;
    }

    @media screen {

        // the viewport-width ("vw") constants are magic; they seem to work for
        // many monospace fonts, but may need tweaking
        @include media-breakpoint-up(xs) {
            font-size: min(2.2vw, var(--doc-ptsize-max));
        }

        @include media-breakpoint-up(md) {
            font-size: min(1.6vw, var(--doc-ptsize-max));
        }

        .grey,
        hr {
            opacity: $hr-opacity;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
        font-size: 1em;
    }

    pre,
    code {
        font-size: 1em;
        overflow: visible;
    }

    pre {
        margin: 0;
        padding: 0;
    }

    .bcp14 {
        font-weight: bold;
        // color: $gray-700;
    }
}

.rfcmarkup {

    // A lot of plaintext documents seem to have line lengths >72ch.
    // To handle that, we calculate with 80ch here and adjust some of the
    // font sizes down accordingly.
    pre {
        width: 80ch;
        white-space: pre-wrap;
    }

    @media screen {

        // the viewport-width ("vw") constants are magic; they seem to work for
        // many monospace fonts, but may need tweaking
        @include media-breakpoint-up(xs) {
            font-size: min(2vw, var(--doc-ptsize-max));
        }

        @include media-breakpoint-up(md) {
            font-size: min(1.5vw, var(--doc-ptsize-max));
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        white-space: pre;
        display: inline;
    }

    .newpage {
        margin-top: -1.25em;
    }

}

tbody.meta tr {

    td:first-child,
    th:first-child,
    td.edit {
        display: none;
    }
}

.sidebar {
    height: 100vh;

    .toplink,
    #name-table-of-contents {
        display: none;
    }

    th,
    td {
        display: block;
        padding: 0;
    }

    td {
        margin-bottom: map.get($spacers, 3);
    }
}

.navbar {

    td:not(:first-child),
    th:not(:first-child) {
        padding-top: map.get($spacers, 3);
    }

}

// Add some padding when there are multiple buttons in a line that can wrap
.buttonlist .btn {
    margin-bottom: map.get($spacers, 1);
}

// Make revision numbers pagination items fixed-width
.revision-list {
    .page-item {
        width: 2.2rem;
    }

    .page-item.rfc {
        width: 6.6rem;
    }
}

#docinfo {
    max-height: 70vh;
    z-index: -1;
}


.badge-obs {
    color: white;
    background-color: $orange-800;
}

.badge-ps {
    color: black;
    background-color: $blue-300;
}

.badge-exp {
    color: black;
    background-color: $yellow-200;
}

.badge-inf {
    color: white;
    background-color: $orange;
}

.badge-ds {
    color: black;
    background-color: $cyan-200;
}

.badge-hist {
    color: white;
    background-color: $gray-700;
}

.badge-std {
    color: black;
    background-color: $teal-200;
}

.badge-bcp {
    color: white;
    background-color: $pink-500;
}

.badge-unkn {
    color: black;
    background-color: $gray-300;
}

.badge-draft {
    color: white;
    background-color: $danger;
}

.badge-generic {
    color: white;
    background-color: $danger;
}

#toc-nav {
    width: inherit;
    overscroll-behavior-y: none; // Prevent overscrolling from scrolling the main content
}


@media print {
    @page {
        size: letter;
        margin: .75in;
    }

    .rfcmarkup {
        font-size: 9.75pt !important;
        line-height: 1.25em !important;
    }

    .rfchtml {
        font-size: 9.75pt;
        line-height: 1.25em;
    }

    body {
        margin: 0;
        padding: 0;
    }

    pre {
        page-break-inside: avoid;
    }

    /*
    a:link,
    a:visited {
        // color: inherit;
        // text-decoration: none;
    }
*/

    .newpage {
        page-break-before: always !important;
    }

    .noprint {
        display: none;
    }
}

// Select2 styling
@import "select2";

.select2-results__option,
.select2-search__field {
    font-size: small !important;
}

.select2-container--open {
    z-index: 9999999;
}
